import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Please step back. This is a restricted area!
        </p>
      </header>
    </div>
  );
}

export default App;
